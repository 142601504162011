//import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

const MyApp = () => {
  return (

    <form>
      <legend>Invoice</legend>

      <div class="mb-3">
        <label for="site" class="form-label">Site</label>
        <select class="form-select" aria-label="site">
          <option selected>Select site</option>
          <option>Site 1</option>
          <option>Site 2</option>
          <option>Site 3</option>
          <option>Site 4</option>
          <option>Site 5</option>
        </select>
      </div>

      <div class="form-group mb-3">
        <label for="mileageInput">Mileage (in miles)</label>
        <input type="number" class="form-control" id="mileageInput" placeholder="Enter mileage" min="0" step="0.1" required />
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" id="food" name="food" value="food" />
        <label class="form-check-label">Include food?</label>
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" id="vanhire" name="vanhire" value="van" />
        <label class="form-check-label">Own van used?</label>
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" id="stopout" name="stopout" value="stopout" />
        <label class="form-check-label">Include stop out?</label>
      </div>


      <div class="mb-3">
        <label for="message" class="form-label">Additional information</label>
        <textarea class="form-control" id="message" rows="3" placeholder="Enter your message"></textarea>
      </div>

      <button type="submit" class="btn btn-primary">Submit</button>
    </form>

  );
};

export default MyApp;